@import 'assets/styles/_fonts.scss', 'assets/styles/_vars.scss', 'assets/styles/_theme.scss', 'assets/styles/_flex.scss',
  'assets/styles/_mixins.scss';

@include responsive-dialogs();

html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: black;
  box-sizing: border-box;
  font-family: 'system', sans-serif;
  font-size: var(--body-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  ::-webkit-scrollbar-track {
    background-color: #1e1e24;
  }
}

// START::FONT/TEXT
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-primary);
}

h2 {
  font-size: var(--h2-font-size);
}

.primary-text {
  color: var(--color-white);
  font-size: 1rem;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer !important;
}

// END::FONT/TEXT

// START::STRUCTURAL
.container {
  margin: 1rem;
}

// .container {
//   padding-top: 20px;
//   display: grid;
//   grid-gap: 30px;
//   grid-template-columns: repeat(auto-fill, 350px);
//   justify-content: center;
// }

// ToDo: Move some classes like this to its own components?
// .event-details-container {
//     .mat-input-element {
//         margin-left: 11px;
//     }
// }
hb-profile {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
    width: 100%;
    background-color: var(--light-black) !important;
    height: auto;
    border-radius: 4px;
  }

  .mat-form-field-infix {
    border-top: none !important;
    text-align: center !important;
    padding-bottom: 5px !important;
  }
}

hb-profile-info {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  .mat-form-field-infix {
    border-top: none !important;
    text-align: left !important;
    padding-bottom: 5px !important;
  }
}

hb-notifications {
  .mat-tab-body-content {
    overflow: hidden !important;
  }
}

.invite-container {
  .mat-form-field-label-wrapper {
    margin-top: -4px !important;
  }

  .input .mat-input-element {
    margin-left: 12px !important;
  }

  .mat-input-element {
    margin-left: 12px !important;
  }
}

.link-container {
  .links {
    .mat-form-field-infix {
      border-bottom: 0.5px solid var(--color-white);
    }
  }
}

.hb-dialog-container {
  width: 100%;
  max-width: 600px;
}

.spacer {
  flex: 1 1 auto;
}

// END::STRUCTURAL

// START::Photo
// .photo-grid {
//   padding-top: 30px;
//   display: grid;
//   grid-gap: 10px;
//   grid-template-columns: repeat(auto-fill, 300px);
//   justify-content: center;
// }
// END::Photo

// START::Material Components
// Mat Dialog (Modal window)
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.7;
}
.cdk-overlay-backdrop {
  background-color: black;
}

.mat-mdc-dialog-content {
  display: flex;
  width: 100%;
}

.mat-mdc-dialog-actions {
  display: flex;
  width: 100%;
}

// Mat Card
.mat-card {
  cursor: pointer;

  .mat-icon {
    color: var(--color-yellow) !important;
  }
}

// .mat-content {
//     margin-left: -20px;
// }

// Mat Tabs
.mdc-tab__text-label {
  color: var(--color-gold) !important;
  text-transform: uppercase;
}

.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  background-color: var(--color-gold) !important;
  border-color: var(--color-gold) !important;
}

// ToDo: Do we need those !important used all over? Delete where possible
// Mat Form field
.mat-form-field {
  display: inline !important;
  width: 100% !important;

  ::placeholder {
    color: var(--view-text-secondary) !important;
  }
}

.mat-form-field-wrapper {
  width: 100%;
  background-color: var(--light-black) !important;
  height: auto;
}

.mat-placeholder-required {
  color: var(--view-text-secondary) !important;
}

.mat-form-field-label {
  padding-top: 3px !important;
}

// Mat Input
.mat-input-element {
  // width: auto !important;
  color: var(--color-white) !important;
}

.mat-label {
  margin-left: 15px;
}

// Mat Autocomplete
// mat auto complete
// .mat-autocomplete-panel {
//     background: var(--mat-panel-bg) !important;
// }
// .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
//     background: var(--mat-panel-bg) !important;
// }

// Mat Expansion panel (Accordion)
// .mat-expansion-panel-header {
//     background-color: var(--color-card-background) !important;
// }
// .mat-expansion-panel-header-title {
//     color: white !important;
// }
// .mat-expansion-panel {
//     background: none;
// }

// Mat Switch (Slide-Toggle) button
.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: var(--color-blue-latest) !important;
  }

  .mat-slide-toggle-bar {
    background-color: rgba(28, 189, 102, 0.2) !important;
  }
}

.mat-slide-toggle-bar {
  background-color: var(--color-gray-1) !important;

  .mat-slide-toggle-label-before .mat-slide-toggle-bar {
    margin-left: 305px i !important;
  }
}

.mat-slide-toggle-content {
  overflow: initial !important;
}

/* .mat-slide-toggle-thumb {
  background-color: var(--color-blue-latest) !important;
} */

// Mat Checkbox
.mat-checkbox-inner-container {
  border: 1px solid var(--view-text-secondary);
  border-radius: 4px !important;
  height: 24px !important;
  width: 24px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background: var(--color-blue-latest) !important;
}

// Mat Icon
.mat-icon {
  cursor: pointer !important;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.mat-icon-button .mat-button-wrapper {
  color: var(--color-white) !important;
}

// Mat Chip (Tags)
// .mat-standard-chip {
//     height: 20px !important;
//     background: var(--color-gray-3) !important;
//     color: var(--color-white) !important;
// }

// .mat-chip-row.mat-standard-chip .mat-chip-remove {
//     color: var(--color-gray-4) !important;
//     opacity: 1 !important;
// }

.clock-face__number .mat-mdc-fab,
.mat-mdc-mini-fab {
  box-shadow: none !important;
}

// CDK Mat Drag and drop
.cdk-drag-preview {
  background-color: var(--color-blue-latest);
  border-radius: 50px;
  width: 24px;
  height: 22px;
  text-align: center;
  font-size: 10px;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// ToDo: Check what this is an example classes?
/* .example-box:last-child {
  border: none;
}
.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
} */
// .example-list {
//     width: 500px;
//     max-width: 100%;
//     border: solid 1px #ccc;
//     min-height: 60px;
//     display: block;
//     border-radius: 4px;
//     overflow: hidden;
//   }
//   .example-box {
//     border-bottom: solid 1px #ccc;
//     color: rgba(0, 0, 0, 0.87);
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//     box-sizing: border-box;
//     cursor: move;
//   }
// .cdk-drag-preview {
//     box-sizing: border-box;
//     border-radius: 4px;
//     box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
//                 0 8px 10px 1px rgba(0, 0, 0, 0.14),
//                 0 3px 14px 2px rgba(0, 0, 0, 0.12);
//   }
//   .cdk-drag-animating {
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
//   }
//   .example-box:last-child {
//     border: none;
//   }
//   .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
//   }
//   .example-custom-placeholder {
//     background: #ccc;
//     border: dotted 3px #999;
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
//   }
#secondRowOne.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#secondRowTwo.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#secondRowThree.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#firstRow.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#thirdRowOne.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#thirdRowTwo.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#thirdRowThree.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#forthRow.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#fifthRowOne.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#fifthRowTwo.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#fifthRowThree.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder),
#sixthRow.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
  display: none;
}

// END::Material Components

// START::Helpers
// ToDo: think of changing those classes to less verbose (combine multiple classes in to one used all over the project, othervise use in components only)
// Structural
.display-inline {
  display: inline !important;
}

.h-100 {
  height: 100%;
}

.h-13 {
  height: 13px;
}

.h-16 {
  height: 16px;
}

.w-100 {
  width: 100%;
}

.w-16 {
  width: 16px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-16 {
  margin-right: 16px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-1 {
  margin-top: 1px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.pl-12 {
  padding-left: 12px;
}

.va-middle {
  vertical-align: middle;
}

// Other
.fs-12 {
  font-size: 12px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-1 {
  opacity: 1;
}

.label-color-secondary {
  color: var(--view-text-secondary);
}

// END::Helpers

// START::COMPONENTS/ELEMENTS
// TopBar
// ToDo: Consider creating Tob Bar component or using classes in proper components if not shared all over the project
.top-bar {
  padding: 20px;
  position: relative;

  > div {
    position: relative;
  }

  .hobby-avatar {
    position: absolute;
    background-image: url(src/assets/icons/football.svg);
    background-size: cover;
    width: 1.3rem;
    height: 1.3rem;
    background-position: top center;
    border-radius: 50%;
    margin: 0;
    margin-left: 19px;
    z-index: 2;
  }

  .hb-logo {
    background-image: url(src/assets/icons/android-chrome-96x96.png);
    background-size: cover;
    position: absolute;
    z-index: 1;
    background-position: top center;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }

  .arrow-back,
  .close-icon,
  .close,
  .delete-icon,
  .copy-icon {
    color: var(--color-gold);
    background-color: transparent;
    border: none;
  }

  .arrow-back:focus-visible,
  .close-icon:focus-visible {
    outline: none;
  }

  .mat-icon {
    width: 24px;
    height: 24px;
  }

  .arrow-back {
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .page-label {
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--color-white);
  }

  mat-form-field {
    height: 32px;
    width: 136px;

    .mat-form-field-appearance-fill .mat-form-field-infix {
      color: rgba(255, 255, 255, 0.87);
      padding: 0 !important;
    }
  }
}

.user-avatar-circle {
  @include user-avatar(32px, 32px, 50%);
}

.user-avatar-rectangle {
  @include user-avatar(40px, 40px, 5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

// Button
button,
a.mdc-button {
  &.primary {
    background-color: var(--color-blue-latest) !important;
    color: white !important;
  }

  &.accent {
    background: transparent !important;
    color: var(--color-blue-latest) !important;
    border: 2px solid var(--color-blue-latest) !important;
  }

  .flex-col & {
    width: 100%;
    margin-left: 0 !important;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.submit-buttons {
  padding: 20px;
}

.error-text {
  color: var(--view-text-secondary);
  font-size: 10px;
}

.team-size {
  .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    overflow: inherit !important;
    padding: 0px !important;
  }
}

.description-mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.hidden-date-picker {
  .mat-button-wrapper {
    visibility: hidden !important;
  }
}

.delete-btn-background {
  display: none;
}

.date-section mat-form-field,
.custom-mat-form-field,
.search mat-form-field {
  @include size-mat-form-fields(100%, 48px);
}

.select-section .description-mat-form-field {
  @include size-mat-form-fields(100%, auto);
}

.team-size mat-form-field {
  @include size-mat-form-fields(30%, 48px);
}

.custom-mat-form-field .mat-mdc-form-field-infix {
  padding: 18px 0 0 !important;
  min-height: 48px !important;
}

.hobby-mat-form-field .mat-mdc-form-field-infix {
  padding: 0 !important;
  min-height: 48px !important;
}

.search .mat-mdc-form-field-infix {
  padding: 10px 0 !important;
}

.mat-mdc-floating-label {
  top: 22px !important;
  font-size: 14px !important;
  left: -10px !important;
}

.mdc-text-field {
  overflow: visible !important;
  padding: 0 10px !important;
}

.team-size .mdc-text-field {
  padding: 0 !important;
}

.location-text,
.team-size-txt {
  margin: 0 4px;
  font-size: 0.875rem;
}

.mat-mdc-select-arrow-wrapper {
  transform: translateX(-18px) translateY(-4px) !important;
}

.mat-mdc-select-arrow {
  color: var(--color-white);
}

.mdc-switch__icons {
  display: none !important;
}

.custom-mat-form-field .mat-mdc-icon-button {
  margin-top: 0;
}

.position-red {
  @include position-style-color(var(--color-red-1));
}

.position-blue {
  @include position-style-color(var(--color-blue-1));
}

.position-green {
  @include position-style-color(var(--color-green-1));
}

.position-yellow {
  @include position-style-color(var(--color-gold));
}

.position-first {
  @include position-style-background(32px, 32px, 0.625rem);
}

.position-default {
  @include position-style-background(26px, 26px, 0.625rem);
}

.position-small {
  @include position-style-background(16px, 16px, 0.375rem);
}

.time-icon {
  position: absolute;
  top: 12px;
  right: 10px;
}

.custom-snack-bar {
  color: var(--mat-snack-bar-button-color) !important;
}

.cyan-event-icon,
.cyan-event-link {
  color: var(--color-blue-latest) !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

// END::COMPONENTS/ELEMENTS

@media only screen and (max-width: 677px) {
}

@media only screen and (min-width: 677px) {
  .del-btn-media-query {
    display: revert;
  }
}

// NAvBAR

.main-container {
  display: flex;

  &>section {
    width: 100%;
  }
}

.navbar {
  position: fixed;
  transition: width 200ms ease;
  z-index: 11;

  .link-text {
    display: none;
    margin-left: 1rem;
    color: var(--color-gold);
    font-weight: 600;
  }

  .navbar-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .nav-item {
      width: 100%;
    }

    .nav-link {
      display: flex;
      align-items: center;
      height: 5rem;
      color: var(--text-primary);
      text-decoration: none;
      filter: grayscale(100%) opacity(0.7);
      transition: var(--transition-speed);

      &.active-link {
        filter: grayscale(0%) opacity(1);
        color: var(--text-secondary);

        mat-icon {
          fill: var(--color-gold) !important;
          color: var(--color-gold) !important;
        }
      }

      &:hover {
        filter: grayscale(0%) opacity(1);
        color: var(--text-secondary);

        mat-icon {
          fill: var(--color-liaght-aqua);
        }
      }

      mat-icon {
        min-width: 2rem;
        margin: 0 1.5rem;
        min-height: 2.5rem;
        fill: var(--color-blue);
        transition: var(--transition-speed);

        &:hover {
          fill: var(--color-gold);
        }
      }

      .settings-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.spinner-container {
  position: absolute;
  height: 50px;
  width: 50px;
  z-index: 99;
  background-color: transparent;
  top: 40%;
  right: calc(50% - 50px);
}

mat-spinner {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
}

@media only screen and (max-width: 600px) {
  .main-container {
    &>section {
      margin: 0;
      height: calc(100% - 80px);
    }
  }

  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;

    .link-text {
      display: inline;
      font-size: 0.625rem;
      margin: 0;
    }

    .navbar-nav {
      flex-direction: row;

      .nav-link {
        justify-content: center;
        flex-direction: column;

        &:hover {
          background-color: var(--bg-secondary);
        }
      }
    }
  }
}

// Middle screen
@media only screen and (min-width: 600px) {
  .main-container {
    margin-left: 5rem;
    justify-content: center;

    &>section {
      display: flex;
      justify-content: center;
      max-width: 1000px;
    }
  }

  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;

    &:hover {
      width: 200px;

      .link-text {
        display: inline;
        transition: opacity var(--transition-speed);
      }
    }

    .nav-link {
      &.active-link {
        background-color: var(--bg-secondary);
      }

      &:hover {
        background-color: var(--color-blue-latest);
      }
    }
  }
}

// Large screen
@media only screen and (min-width: 1024px) {
  .navbar:hover {
    width: 18%;
  }
}