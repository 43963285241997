@use '@angular/material' as mat;
// @import './component-themes';

@include mat.core();
// @include mat.legacy-core(); // this is the important line

$primary: mat.define-palette(mat.$cyan-palette);
$accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$theme: mat.define-dark-theme((color: (primary: $primary,
        accent: $accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

$light-primary: mat.define-palette(mat.$amber-palette);
$light-accent: mat.define-palette(mat.$cyan-palette, A400, A100, A700);
$light-theme: mat.define-light-theme((color: (primary: $light-primary,
        accent: $light-accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.core-theme($theme);
@include mat.all-component-themes($theme);

.color-scheme-dark {
  // @include mat.core-theme($theme);
  /* to reduce css part delete all-component-themes and use include for separate mat modules */
  // @include mat.all-component-themes($theme);
  /* @include mat.button-theme($theme);
  @include mat.icon-theme($theme); */

  // Theming works only for components. So for styling outside material components this should be styled
  .navbar {
    background-color: var(--bg-primary);
  }

  .info-text {
    color: var(--view-text-secondary);
  }

  a {
    color: var(--color-white)
  }

  .background-host-page {
    background-color: var(--color-page-background);
  }

  .create-button {
    background-color: var(--color-blue-latest) !important;
  }

  .mat-mdc-card {
    background-color: var(--color-card-background);
  }

  .fill-button {
    background-color: var(--color-blue-latest) !important;
    color: var(--color-white) !important;
  }

  .outline-button {
    background: transparent;
    color: var(--color-blue-latest) !important;
    border: 2px solid var(--color-blue-latest);
  }

  .select-template {
    color: var(--color-blue-latest) !important;
  }

  .location-text {
    color: var(--color-white);
  }

  .card-container {
    background-color: var(--color-card-background);
    color: var(--color-white);
  }

  .date-group {
    background-color: transparent;
    color: var(--color-white);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-blue);
  }
}

.color-scheme-light {
  @include mat.core-color($light-theme);
  /* to reduce css part delete all-component-themes and use include for separate mat modules */
  @include mat.all-component-colors($light-theme);
  /* @include mat.button-color($light-theme);
  @include mat.icon-color($theme); */

  // Theming works only for components. So for styling outside material components this should be styled
  .navbar {
    background-color: var(--color-light-topbar);
  }

  a {
    color: var(--color-page-background)
  }

  a .mat-icon {
    color: var(--color-white)
  }

  .background-host-page {
    background-color: var(--color-white);
  }

  .mat-mdc-tab-header {
    background-color: var(--color-light-topbar);
    box-shadow: 0px 1px 15px 0px var(--color-shadow);
  }

  .create-button,
  .top-bar {
    background-color: var(--color-light-topbar) !important;
  }

  .navbar .navbar-nav .nav-item .nav-link.active-link {
    background-color: var(--color-active-link);
  }

  .mat-mdc-card {
    background-color: var(--color-white);
  }

  .fill-button,
  .user-profile,
  .fill-area {
    background-color: var(--color-light-topbar) !important;
    color: var(--color-white) !important;
  }

  .outline-button {
    background: var(--color-white);
    color: var(--color-light-topbar) !important;
    border: 2px solid var(--color-light-topbar);
  }

  .select-template {
    color: var(--color-light-topbar) !important;
  }

  .location-text {
    color: var(--color-black);
  }

  .card-container, .date-group {
    background-color: var(--color-white);
    color: var(--color-black);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-light-topbar);
  }
}

.color-scheme-dark.app-background {
  background-color: var(--color-dark-background);
  color: var(--color-white);
}

.color-scheme-light.app-background {
  background-color: var(--color-light-background);
  color: var(--bg-secondary)
}