:root {
  --base-scale: calc(100vw / 75);
  --body-font-size: max(1em, min(4em, calc(var(--base-scale))));
  --h2-font-size: max(1em, min(4em, calc(var(--base-scale) * 4)));
  --padding-size: max(1em, min(2em, calc(var(--base-scale) * 2)));
  --margin-size: max(0.5em, min(2em, calc(var(--base-scale) * 2)));
  --border-radius: max(1em, min(3em, calc(var(--base-scale) * 3)));
  --transition-speed: 600ms;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --bg-third: #2b2b2d;

  --color-gray-1: #929292;
  --color-gray-2: #414141;
  --color-gray-3: #979797;
  --color-gray-4: #c4c4c4;

  --color-white: #ffffff;
  --color-green: #036a63;
  --color-mid-green: #045650;
  --color-dark-green: #00443f;

  --color-teal: #01a299;
  --color-teal2: rgba(1, 162, 153, 0.6);

  --color-light-green: #80c526;
  --color-lightmid-green: #65981a;
  --color-dark-green: #1cbd66;
  --color-green-1: #219653;
  --color-open-event: #7dc29d;

  --color-liaght-aqua: #85e0e2;
  --color-agua: #68c6c6;
  --color-dark-aqua: #4faaad;

  --color-blue: #4aadcb;
  --color-mid-blue: #169ec2;
  --color-blue-1: #2f80ed;

  --color-yellow: #f9bf24;
  --color-orange: #d99f19;

  --color-sandy-brown: #c19b6d;

  --color-red: #f5545f;
  --color-dark-red: #da4e4e;
  --color-red-1: #eb5757;
  --color-full-event: #f68383;

  --color-gray: #90878c;
  --color-dark-gray: #585759;

  --color-light-brown: #88593f;
  --color-brown: #644834;

  --color-page-background: #121212;
  --color-light-background: #d8d8d8;
  --color-dark-background: #303030;
  --color-light-topbar: #036058;
  --color-shadow: rgba(88, 87, 89, 0.6);
  --color-active-link: rgba(196, 196, 196, 0.3);
  --color-black: rgba(0, 0, 0, 0.54);
  --color-card-background: #383838;
  --color-buttom-leave-background: #0b0808;
  --view-text-primary: rgba(255, 255, 255, 0.87);
  --view-text-secondary: rgba(255, 255, 255, 0.6);
  --border-line: rgba(196, 196, 196, 0.3);
  --mat-panel-bg: #2d2d2d;
  --light-black: #2f2f2f;
  --color-blue-latest: #26c6da;
  --color-gold: #ffca28;
  --color-red: #d92929;

  // Sizes
  --size-field-h: 56px;
}