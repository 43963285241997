// Migrated from flex-layout: https://github.com/angular/flex-layout/issues/1426

// Use https://material.angular.io/cdk/layout/overview for isMobile breakpoints
// Flex Tips https://css-tricks.com/snippets/css/a-guide-to-flexbox/

// Flex row/col + grid.
.flex-row {
  display: flex !important;
  flex-direction: row;
}
.flex-col {
  display: flex !important;
  flex-direction: column;
}
.grid {
  display: grid;
}
.flex {
  display: flex !important;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Flex-wrap utils.
.flex-wrap {
  flex-wrap: wrap;
} // Mostly used with flex-row, when wrapping is desired.

// Size changes according to "currentScreenSize" in app.component
.flex-col-xs {
  @media screen and (max-width: 677px) {
    flex-direction: column;
  }
}
.flex-col-sm {
  @media screen and (min-width: 678px) {
    flex-direction: column;
  }
}
.flex-row-xs {
  @media screen and (max-width: 677px) {
    flex-direction: row;
  }
}
.flex-row-sm {
  @media screen and (min-width: 678px) {
    flex-direction: row;
  }
}

// Gap.
.gap-0 {
  gap: 0px;
}
.gap-8 {
  gap: 8px;
}
.gap-10 {
  gap: 10px;
}
.gap-16 {
  gap: 16px;
}
.gap-10 {
  gap: 10px;
}
.gap-25 {
  gap: 25px;
}
.gap-30 {
  gap: 30px;
}
.gap-50 {
  gap: 50px;
}

// Justify content.
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.stretch {
  justify-content: stretch;
}
.start {
  justify-content: start;
}
.end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}

// Align items. (Naming inspiration: https://tailwindcss.com/docs/align-items.)
.align-stretch {
  align-content: stretch;
}
.content-start {
  align-content: flex-start;
}
.flex-start {
  align-items: flex-start;
}
.flex-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}

// Flex/grow/shrink properties https://developer.mozilla.org/en-US/docs/Web/CSS/flex.
.flex-1 {
  flex: 1;
} // Same as flex: 1 1 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-grow {
  flex-grow: 1;
} // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc.
.flex-auto {
  flex: 1 1 auto;
}

// Hide & show for different breakpoints.
.hide-xs {
  @media screen and (max-width: 677px) {
    display: none;
  }
} // Hide on mobile.
.hide-gt-xs {
  @media screen and (min-width: 678px) {
    display: none;
  }
} // Show only on mobile. Hide on desktop.
.hide-sm {
  @media screen and (max-width: 959px) {
    display: none;
  }
} // Hide on mobile/tablet.
.hide-gt-sm {
  @media screen and (min-width: 960px) {
    display: none;
  }
} // Show only on mobile/tablet. Hide on desktop.
