// https://sass-lang.com/documentation/at-rules/mixin/

@mixin page-auto-w-h-dark-bg {
  background-color: var(--color-page-background);
  width: 100%;
  height: 100%;
}

@mixin responsive-dialogs {
  @media screen and (max-width: 959px) {
    .modal-panel {
      min-width: 0 !important;
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;

      mat-dialog-container {
        border-radius: 0;
        padding: 1rem !important;
      }
    }

    .backdrop-modal-panel {
      background-color: var(--bg-primary) !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

@mixin responsive-layout($maxWidth, $margin) {
  @media only screen and (min-width: 600px) {
    display: block;
    margin: $margin auto;
    max-width: $maxWidth;
  }
}

@mixin size-mat-form-fields($width, $height) {
  width: $width;
  height: $height;
}

@mixin page-container {
  background-color: var(--color-page-background);
}

@mixin position-style-background($width, $height, $fontSize) {
  position: relative;
  border-radius: 50px;
  width: $width;
  height: $height;
  text-align: center;

  .position-text {
    margin: 0;
    color: var(--color-white);
    font-size: $fontSize;
    display: flex;
    justify-content: center;
    line-height: $height;
    vertical-align: middle;
  }
}

@mixin position-style-color($bg-color) {
  background-color: $bg-color;
}

@mixin user-avatar($width, $height, $borderRadius) {
  border-radius: 50%;
  margin-right: 12px;
  margin-bottom: 0 !important;
  background-color: var(--color-blue-latest);
  width: $width;
  height: $height;

  .image {
    background-size: contain;
    width: $width;
    height: $height;
    border-radius: $borderRadius;
  }

  .initials {
    color: --var(--color-white);
    font-size: 1rem;
    line-height: 1.75rem;
    text-align: center;
  }
}