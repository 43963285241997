/* Define the "system" font family */
@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'), local('.LucidaGrandeUI'),
    local('Ubuntu Light'), local('Segoe UI Light'), local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}

// https://developers.google.com/fonts/docs/material_icons
@font-face {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
}

$font-roboto: 'Roboto', sans-serif;